import { initialState } from "~/utils/state/initialState";
import { UpdateNestedProperty } from "./UpdateNestedProperty";

export const Reducer = (state: any, action: { type: string; payload: any }) => {
  switch (action.type) {
    case "UPDATE":
      return UpdateNestedProperty(state, action.payload);
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

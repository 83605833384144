export const initialState: { [key: string]: any } = {
  app: {
    delete: {
      id: null,
      name: ""
    }
  },
  sort_collection: {
    title: "",
    description: "",
    product_limits: 250,
    product_strategies: [],
    is_active: true,
    exclude_products: null,
    exclude_products_title: null,
    pined_products: null,
    sort_strategy_id: 1,
    job_status: "Pending",
    direction: "ASC",
    frequency: String(60 * 12),
    products: {}
  },

  sort_strategies: [],

  validation: {
    rules: [{ title: ["email", "max:100"] }],
    errors: {
      title: ["Must be a valide email"]
    }
  }
};

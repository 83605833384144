import type { AppState } from "~/types/store";

export const UpdateNestedProperty = (
  state: AppState,
  { key, value }: { key: string; value: any }
): AppState => {
  // Split the key string into an array of keys
  const keys = key.split(".");

  // Copy the state object to avoid mutating the original state
  const newState = { ...state };

  // Traverse the nested structure to reach the target property
  let currentState: any = newState;
  for (let i = 0; i < keys.length - 1; i++) {
    currentState = currentState[keys[i]];
  }

  // Update the target property with the new value
  currentState[keys[keys.length - 1]] = value;

  return newState;
};

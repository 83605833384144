import {
  createContext,
  useReducer,
  type ReactNode,
  type Dispatch
} from "react";
import { Reducer } from "./Reducer";
import { initialState } from "~/utils/state/initialState";

interface AppAction<T = any> {
  type: string;
  payload?: T;
}

interface ContextArgs {
  state: any;
  dispatch: Dispatch<AppAction>;
}

export const AppContext = createContext<ContextArgs | undefined>(undefined);

const StoreProvider = ({ children }: ReactNode | any) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch } as ContextArgs}>
      {children}
    </AppContext.Provider>
  );
};

export default StoreProvider;
